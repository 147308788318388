<template>
  <div class="device-details need-details">
    <Header />
    <div class="w-1200" v-if="this.need[0].del_flag == 1">
      <div class="company-info">
        <!-- <img src="~@/assets/images/temp/temp_02.png" class="avatar" alt="" /> -->
        <img :src="need[0].consumer_head" class="avatar" alt="" />

        <div class="content">
          <div class="name">{{ need[0].company_name }}</div>
          <div class="date">{{ need[0].create_time }}</div>
        </div>
        <div class="btn-private-letter" @click="dialog"><i class="f-icon icon-private-letter"></i>私信TA</div>
        <el-dialog :title="need[0].create_by" :visible.sync="dialogVisible" width="40%">
          <el-input type="textarea" :rows="7" placeholder="请输入想要发送的信息" v-model="textarea"> </el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">发 送</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="description">
        <div class="description-header"><span class="title">详情描述</span></div>
        <div class="parameter">
          <ul>
            <!-- <li>设备分类：{{ need[0].type_name }}</li> -->
            <li>设备分类：{{ need[0].type_name }}</li>
            <li>
              租赁方式：{{ need[0].rental_type == 0 ? '按日' : need[0].rental_type == 1 ? '按月' : need[0].rental_type == 2 ? '半年' : '年' }}
              <!-- <span class="type" v-for="(item, index) in need[0].rental_type" :key="index">{{ item.rental_fee }}/{{ item.rental_type == 0 ? '日' : item.rental_type == 1 ? '月' : item.rental_type == 2 ? '半年' : '年' }}</span> -->
            </li>
          </ul>
        </div>
        <div class="detail-content">
          <!-- class="pic-list" -->
          <div>
            <div class="xiangqing">
              <div class="font" v-if="need[0].name">
                标题名称: <span class="content">{{ need[0].name }}</span>
              </div>
              <div class="font" v-if="need[0].device_brand">
                设备品牌: <span class="content">{{ need[0].device_brand }}</span>
              </div>
              <div class="font" v-if="need[0].address">
                地址: <span class="content">{{ need[0].address }}</span>
              </div>
              <div class="font" v-if="need[0].rental_num">
                租赁时间: <span class="content">{{ need[0].rental_num }}天</span>
              </div>
              <!-- <div class="font" v-if="need[0].status">
                状态: <span class="content">{{ need[0].status == 1 ? '正常' : '停用' }}</span>
              </div> -->
            </div>
            <div>
              <div class="font">
                <span>&nbsp;</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].seekrent_type">
                项目类型: &nbsp;<span v-for="(item,index) in need[0].seekrent_type" :key="index" class="content">{{item}}</span>
              </div>
            </div>

            <div>
              <div class="font">求租内容:</div>
              <div class="rent" v-html="need[0].seekrent_content"></div>
            </div>
            <div v-html="need[0].detail_info" class="info"></div>
            <div v-if="need[0].seekrent_img">
              <div class="font">求租图片</div>
              <div class="showImg">
                <el-image class="img" v-for="(item, index) in need[0].seekrent_img" :key="index" :src="item" :preview-src-list="need[0].seekrent_img"> </el-image>
              </div>
            </div>
            <!-- <div>
              <div class="font" v-if="need[0].audit_state">
                审核状态: <span class="content">{{ need[0].audit_state == 1 ? '待审核' : need[0].audit_state == 2 ? '审核通过' : need[0].audit_state == 3 ? '审核失败' : '审核驳回' }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].audit_by">
                审核人: <span class="content">{{ need[0].audit_by }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].audit_time">
                审核时间: <span class="content">{{ need[0].audit_time }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].audit_reason">
                审核失败原因: <span class="content">{{ need[0].audit_reason }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].create_by">
                创建者: <span class="content">{{ need[0].create_by }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].update_time">
                更新时间: <span class="content">{{ need[0].update_time }}</span>
              </div>
            </div>
            <div>
              <div class="font" v-if="need[0].update_by">
                更新者: <span class="content">{{ need[0].update_by }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="delete w-1200">
    该需求已删除,请重新选择需求
    </div> -->
    <div v-if="this.need[0].del_flag == 2" class="delete w-1200">
    该需求已删除,请重新选择需求
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import RightNav from '@/components/rightNav'
import { details } from '@/api/needDetails.js'
import kits from '@/kits/index.js'

export default {
  components: {
    Header,
    Footer,
    RightNav
  },
  data() {
    return {
      id: {
        inputs: {
          device_seekrent_id: ''
        }
      },
      dialogVisible: false,
      textarea: '',
      need: [
        {
          del_flag: '',
          seekrent_content: '无',
          address: '未知',
          audit_state: 2,
          create_time: '',
          type_id: null,
          rental_num: '',
          latitude: null,
          seekrent_type: '',
          seekrent_img: '',
          rental_type: '',
          audit_time: '',
          create_by: '无',
          consumer_id: '',
          update_time: '',
          device_brand: '无',
          device_seekrent_id: '',
          audit_by: '',
          name: '无',
          audit_reason: null,
          update_by: '',
          status: '',
          longitude: null,
          consumer_head:'',
          company_name: '',
        }
      ]
    }
  },
  created() {
    this.id.inputs.device_seekrent_id = this.$route.query.id
    details(this.id)
      .then(res => {
        // console.log(res);
        this.need = res.data
        // 处理图片
        let img = JSON.parse(this.need[0].seekrent_img)
        console.log('aaa',img);
        let imgs = []
        for (let i = 0; i < img.length; i++) {
          imgs.push(kits.img.url(img[i]))
        }
        this.need[0].seekrent_img = imgs
        // 处理公司头像
        let cimg = kits.img.url(this.need[0].consumer_head)
        this.need[0].consumer_head = cimg
        console.log(this.need[0].consumer_head);
        // 处理项目
        let time = this.need[0].seekrent_type.split(',')
        for (let i = 0; i <= time.length - 1; i++) {
          if (time[i] == 1) {
            time[i] = '房建工程'
          } else if (time[i] == 2) {
            time[i] = '市政工程'
          } else if (time[i] == 3) {
            time[i] = '公路工程'
          } else if (time[i] == 4) {
            time[i] = '水利工程'
          } else if (time[i] == 5) {
            time[i] = '园林工程'
          } else if (time[i] == 6) {
            time[i] = '桥隧工程'
          } else if (time[i] == 7) {
            time[i] = '其他'
          }
        }
        this.need[0].seekrent_type = time
        console.log( this.need[0].seekrent_type);
        // 处理创建时间
        this.need[0].seekrent_img = imgs
        let sub1 = this.need[0].create_time.slice(0, 10)
        this.need[0].create_time = sub1
        // 处理审判时间
        // let sub2 = this.need[0].audit_time.slice(0, 10)
        // this.need[0].audit_time = sub2
        // 处理更新时间
        // let sub3 = this.need[0].audit_time.slice(0, 10)
        // this.need[0].update_time = sub3
        // for(let i = 0; i<this.need[0].seekrent_type.length; i++) {
        //   if(typeof this.need[0].seekrent_type[i] != 'string') {
        //     this.need[0].seekrent_type.splice(i,1)
        //     console.log(this.need[0].seekrent_type);
        //   }
        // }
      })
      .catch(err => {
        return err
      })
  },
  mounted() {},
  methods: {
    dialog() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.avatar {
  border: 1px solid black;
}
.type {
  margin-right: 15px;
}
.info {
  display: block;
}
/* .font {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
} */
.content {
  margin-right: 10px;
  /* font-size: 17px;
  font-weight: 700; */
}
.showImg {
  display: flex;
  width: 1250px;
  flex-wrap: wrap;
}
.showImg .img {
  /* display: inline-block; */
  width: 380px;
  height: 215px;
  margin-right: 20px;
}
.rent {
  white-space: pre-wrap;
}
.xiangqing div {
  float: left;
  margin-right: 25px;
}
.xiangqing div::after {
  clear: auto;
}
.delete {
  width: 1200px;
  height: 300px;
  margin-top: 200px;
  text-align: center;
  font-size: 28px;
}
</style>
